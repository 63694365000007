<template>
  <div class="login login-4 login-signin-on d-flex flex-row-fluid" id="kt_login">
    <div
      class="d-flex flex-center flex-row-fluid bgi-size-cover bgi-position-top bgi-no-repeat"
      style="background-image: url('assets/media/bg/bg-3.jpg')"
    >
      <div class="login-form text-center p-7 position-relative overflow-hidden">
        <!--begin::Login Header-->
        <div class="d-flex flex-center mb-15">
          <a href="#">
            <Logo class="max-h-150px" />
          </a>
        </div>
        <!--end::Login Header-->
        <!--begin::Login Sign in form-->
        <div class="login-signin">
          <div class="mb-20">
            <h3 class="display-3">Авторизация по ЭЦП</h3>
            <div class="text-muted font-weight-bold">Вставьте USB токен Jakarta:</div>
          </div>
          <div class="alert alert-danger" v-if="websocket_not_connected" role="alert">
            Ошибка подключения к сервису ЭЦП,
            <a href="/assets/setup_client.exe" class="alert-link"> Скачать клиент</a>
          </div>
          <form class="form" id="kt_login_signin_form" @submit.prevent="tokenAuth">
            <button type="submit" class="btn btn-secondary font-weight-bold px-9 py-4 my-3 mx-4">
              <span class="svg-icon svg-icon-danger"
                ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\legacy\metronic\theme\html\demo1\dist/../src/media/svg/icons\Devices\Usb-storage.svg--><svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="24" height="24" />
                    <path
                      d="M11.5,17.4497475 L6.55025253,12.5 C6.15972824,12.1094757 6.15972824,11.4763107 6.55025253,11.0857864 L13.6213203,4.01471863 C14.4023689,3.23367004 15.6686989,3.23367004 16.4497475,4.01471863 L19.9852814,7.55025253 C20.76633,8.33130112 20.76633,9.59763107 19.9852814,10.3786797 L12.9142136,17.4497475 C12.5236893,17.8402718 11.8905243,17.8402718 11.5,17.4497475 Z"
                      fill="#000000"
                    />
                    <path
                      d="M6.90380592,19.9246212 L4.0753788,17.0961941 C3.88011665,16.9009319 3.88011665,16.5843494 4.0753788,16.3890873 L6.55025253,13.9142136 L10.0857864,17.4497475 L7.6109127,19.9246212 C7.41565056,20.1198833 7.09906807,20.1198833 6.90380592,19.9246212 Z"
                      fill="#000000"
                      opacity="0.3"
                    />
                  </g></svg
                ><!--end::Svg Icon--></span
              >
              Вход по ЭЦП
            </button>
          </form>
          <div class="mt-10">
            <span class="opacity-70 mr-4">Еще нет аккаунта?</span>
            <router-link
              :to="{ name: 'registration' }"
              class="text-muted text-hover-primary font-weight-bold"
            >
              Регистрация
            </router-link>
          </div>
        </div>
        <!--end::Login Sign in form-->
      </div>
    </div>
  </div>
</template>

<script>
// import { ref } from "vue";
import api from "@/core/api";
import { getUrl, isUrlExists } from "@/core/utils/returnUrlController";
import { auth } from "@/core/services/auth";

const authTokenDataMock = {
  // data: {
  //   // signature: "string",
  // },
};

import Logo from "@/assets/icons/logo.svg";
export default {
  components: { Logo },
  data() {
    return {
      socket: null,
      cb: null,
      in_state: null,
      error: null,
      websocket_not_connected: false,
    };
  },
  methods: {
    tokenState: function () {
      console.log("tokenState");
      const request = {
        command: "token_state",
        callback: "qqq",
      };

      this.socket.send(JSON.stringify(request));
    },

    getPin: function () {
      // if( typeof $.cookie('token_pin') !== 'undefined'){
      //   if( $.cookie('token_pin') !== 'null') {
      //     let token_pin = $.cookie("token_pin");
      //     console.log("Cookie PIN:"+ token_pin );
      //     return token_pin;
      //   }
      // }
      //else
      let token_pin = prompt("Введите пинкод");
      if (token_pin) {
        return token_pin;
      }
      return undefined;
    },
    tokenAuth: function () {
      console.log("Log in");
      if (this.socket.readyState !== 1) {
        alert("Сервис по работе с ключами не найден. Нужно установить сервис работы с Ключами");
        // window.location.href = '/sso/service/'
        return;
      }

      let pin = this.getPin();

      if (pin) {
        const auth = {
          command: "login",
          parameters: {
            pin: pin,
          },
        };

        this.cb = (response) => {
          if (response.success) {
            // $.cookie("token_pin", pin, { path: "/" });
            this.tokenSignData("LoginMe", this.authByToken);
          } else {
            alert("Pin-код не верен");
          }
        };

        this.socket.send(JSON.stringify(auth));
      }
    },
    redirectToBack: function (id) {
      const returnUrl = getUrl(id);
      window.open(returnUrl, "_self");
    },
    getToken: function (id) {
      api.getToken(id).then((resp) => {
        auth.setToken(resp.data.access_token);
        this.$router.push({ name: "profile" });
      });
    },
    authByToken: async function (response) {
      console.log(response.result);
      try {
        const tokenData = Object.assign({}, authTokenDataMock);
        tokenData.signature = response.result;
        const {
          data: { id },
        } = await api.getAuthTokenByCert(tokenData);
        if (isUrlExists()) {
          this.redirectToBack(id);
        } else {
          this.getToken(id);
        }
      } catch (e) {
        console.error(e);
      }
    },
    tokenSignData: function (param, callback) {
      this.in_state = "sign";

      const sign = {
        command: "sign",
        parameters: {
          message: param,
        },
      };

      this.cb = callback;
      this.socket.send(JSON.stringify(sign));
    },
    connectToServer: function () {
      this.socket = new WebSocket("ws://127.0.0.1:8090/");
      console.log("WebSocket Connecting...");
      this.socket.onopen = () => {
        console.log("WebSocket Connected OK");
        this.tokenState();
      };
      this.socket.onmessage = (event) => {
        let resp = JSON.parse(event.data);
        console.log(resp);
        console.log("WS Callback", this.cb);

        let callback = this.cb;
        this.cb = undefined;
        if (callback != null && typeof callback !== "undefined") {
          callback(resp);
        } else if (resp.generator_progress) {
          console.log(resp.generator_progress);
        } else if (resp.token_connected) {
          this.tokenAuth();
        } else if (resp.error === "such doctor does not exist") {
          console.log("Токен не зарегестрирован");
        } else if (!resp.token_connected) {
          alert("Нужно подключить USB Ключ Jakarta");
          // in_state = "";
          // $.cookie('token_pin',null, { path: '/' });
          // setTimeout(function(){
          //     if(is_authenticated) {
          //         window.location.href="/logout/";
          //     }
          // },200);
        }
      };
      this.socket.onclose = () => {
        console.log("WebSocket Disconnected");
        this.websocket_not_connected = true;
        this.error = "Ошибка подключения к клиену ЭЦП, нужно запустить или установить клиент";
      };
    },
  },
  beforeUnmount() {
    console.log("close socket");
    if (this.socket && this.socket.readyState === 0) {
      this.socket.close;
    }
  },
  mounted() {
    setTimeout(this.connectToServer, 1000);
  },
  setup() {},
};
</script>

<style lang="scss" scoped src="./LoginToken.scss"></style>
